@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url('./assets/fonts/Roboto-Regular.ttf') format('truetype');
}

a {
  text-decoration: none;
}

body {
  font-family: 'Roboto';
  background-color: #F4F4FA !important;
}

.appContainer {
  margin-top: 104px;

}

.appContainerMap {
  margin-top: 120px;
  padding: 0px;
}

.iiz__hint {
  display: none;
}

.leaflet-container {
  width: 100%;
  height: 100%;
  min-height: 175px;
}

.leaflet-container.disabled img {
  filter: grayscale(100%) blur(8px);
  pointer-events: none;
}

.carousel-container-with-scrollbar {
  padding-left: 100px;
  padding-bottom: 20px;
  overflow: visible !important;
}
.custom-slider {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 20px;
}
.custom-slider__input {
  width: 100%;
}
.slider-image-item {
  padding: 20px 0 20px 20px;
}

@media (max-width: 600px) {
  .appContainer {
    margin-top: 80px;
  }
  .appContainerMap {
    margin-top: 80px;
  }
}