@-webkit-keyframes fadeOut {
  0% { opacity: 1;}
  99% { opacity: 0}
  100% { width: 0; height: 0;}
}

@keyframes fadeOut {
  0% { opacity: 1;}
  99% { opacity: 0;}
  100% { width: 0; height: 0;}
}