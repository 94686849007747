/**
 * Main wrapper
 */
.select-search {
    width: 100%;
    position: relative;
    box-sizing: border-box;
    margin-top: 3px;
    display: block;
    padding: 6px 0 7px;
}

.select-search *,
.select-search *::after,
.select-search *::before {
    box-sizing: inherit;
}

/**
 * Value wrapper
 */
.select-search__value {
    position: relative;
    z-index: 1;
}

.input-icon-down {
    content: '';
    display: inline-block;
    position: absolute;
    right: 19px;
    width: 11px;
    height: 11px;

    top: 20px;
    right: 7px;
    position: absolute;
    pointer-events: none;
    z-index: 2;
    color: rgba(0, 0, 0, 0.54);
}
/**
 * Input
 */
.select-search__input {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 40px 0 16px;
    background: #fff;
    border: 0;
    border-bottom: 1px solid #888888;
    outline: none;
    font-size: 16px;
    line-height: 36px;
    border-radius: 0px;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
    -webkit-appearance: none;
}

.select-search__input:not([readonly]):focus {
    cursor: initial;
}

/**
 * Options wrapper
 */
.select-search__select {
    background-color: white;
    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
}

/**
 * Options
 */
.select-search__options {
    list-style: none;
    width: 100% !important;
    padding: 0px !important;
}

/**
 * Option row
 */
.select-search__row:not(:first-child) {
    border-top: 1px solid #eee;
}

/**
 * Option
 */
.select-search__option,
.select-search__not-found {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 16px;
    background: #fff;
    border: none;
    outline: none;
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
}

.select-search--multiple .select-search__option {
    height: 48px;
}

.select-search__option.is-selected {
    background: #aaaaaa;
    color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
    background: #eeeeee;
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
    background: #aaaaaa;
    color: #fff;
}

/**
 * Group
 */
.select-search__group-header {
    font-size: 10px;
    text-transform: uppercase;
    background: #eee;
    padding: 8px 16px;
}

/**
 * States
 */
.select-search.is-disabled {
    opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
    background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
    cursor: pointer;
}

/**
 * Modifiers
 */
.select-search--multiple {
    border-radius: 3px;
    overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple)
    .select-search__value::after {
    transform: rotate(45deg);
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    pointer-events: none;
}

.select-search--multiple .select-search__input {
    cursor: initial;
}

.select-search--multiple .select-search__input {
    border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
    cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
    border-bottom: 2px solid black;
}

.select-search:not(.select-search--multiple) .select-search__select {
    position: absolute;
    z-index: 2;
    top: 44px;
    right: 0;
    left: 0;
    border-radius: 3px;
    overflow: auto;
    max-height: 360px;
}

.select-search--multiple .select-search__select {
    position: relative;
    overflow: auto;
    max-height: 260px;
    border-top: 1px solid #eee;
    border-radius: 0 0 3px 3px;
}

.select-seatch__list_modal .select-search__select {
    max-height: 30vh !important;
}

.select-search__not-found {
    height: auto;
    padding: 16px;
    text-align: center;
    color: #888;
}

.loading-select-search {
    text-align: center !important;
}

.loading-select-search div {
    width: 30px !important;
    height: 30px !important;
    margin-top: 10px;
}

/*Feature object styles*/

.feature-object-container {
    display: flex;
    width: 100% !important;
    margin-bottom: 10px;
    margin-top: 20px;
    border-bottom: 1px solid blue;
}
.circle-tipe-object {
    display: flex;
    align-items: center !important;
}
.circle-tipe-object div {
    width: 30px !important;
    height: 30px !important;
    background-color: grey;
    border-radius: 90px;
    display: flex;
    align-items: center !important;
    justify-content: center;
}
.actions-feature-object {
    display: flex;
    padding-top: auto;
    align-items: center;
}
.actions-feature-object svg {
    cursor: pointer;
}
.actions-feature-object svg:hover {
    color: blue;
}
.info-feature-object p {
    text-align: left;
    margin: 0px;
    margin-left: 5px;
}
.info-feature-object span {
    margin-right: 15px !important;
}
.info-feature-object {
    text-align: right !important;
    width: 100% !important;
}

.info-row-object p {
    text-align: left;
    margin: 0px;
    text-align: center;
    margin-bottom: 10px;
}
.info-row-object span {
    margin-right: 15px !important;
}
.info-row-object {
    text-align: right !important;
    width: 100% !important;
    display: flex;
    flex-wrap: wrap;
}

@media (max-width: 960px) {
    .info-row-object p {
        flex: 49% !important;
        max-width: 49% !important;
    }
}
@media (max-width: 650px) {
    .info-row-object p {
        flex: 100% !important;
        max-width: 100% !important;
    }
}

.add-feature-object {
    text-align: left !important;
    position: absolute !important;
    right: 0px !important;
    color: blue !important;
    background-color: white !important;
    height: 25px;
    border-radius: 10px !important;
    cursor: pointer !important;
    margin-top: 0px !important;
    margin-bottom: 10px !important;
}

.myIframeEmbed {
    height: 100vh;
}

.dateFormInput input {
    padding: 3px 0 7px;
}

.disabled-quote-input > div::before {
    border-bottom-style: none !important;
}
.disabled-quote-input label {
    color: rgba(0, 0, 0, 0.54);
}
.disabled-quote-input input {
    color: rgba(0, 0, 0) !important;
}

.error-dynamic-input {
    color: red;
    position: relative;
    margin: 0px;
    top: 0px;
}

.error-dynamic-input-select {
    color: red;
    position: relative;
    margin: 0px;
    top: -6px;
}
